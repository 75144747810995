import { ReactElement } from "react";
import { NavButton } from "components/chakra-ui/Button";
import { useRouter } from "next/router";
import {
  SIGNIN_CLEVER_PATH,
  SIGNIN_ERROR_QUERY_PARAM,
  SIGNIN_FIRSTBOOK_PATH,
  SIGNIN_REDIRECT_QUERY_PARAM,
} from "utils/constants";
import extractParam from "utils/extractParam";
import { Alert, Flex } from "@chakra-ui/react";
import FirstBookBadgeSVG from "components/chakra-ui/svg/FirstBookBadge";
import FirstBookLogo from "components/chakra-ui/svg/FirstBookLogo";
import CleverLogo from "components/chakra-ui/svg/CleverLogo";
import CleverBadgeSVG from "components/chakra-ui/svg/CleverBadge";
import { SystemStyleObject } from "@chakra-ui/react";

const CleverBadge: React.FC = (): ReactElement => (
  <CleverBadgeSVG
    mr={4}
    height={["35px", null, "60px"]}
    width={["35px", null, "60px"]}
  />
);
const FirstBookBadge: React.FC = (): ReactElement => (
  <FirstBookBadgeSVG
    mr={2}
    height={["30px", null, "55px"]}
    width={["30px", null, "55px"]}
  />
);

const SigninOption: React.FC = () => {
  const { query } = useRouter();

  const signinError = extractParam(query, SIGNIN_ERROR_QUERY_PARAM);
  const redirectTo = extractParam(query, SIGNIN_REDIRECT_QUERY_PARAM);

  const cleverUrl = { pathname: SIGNIN_CLEVER_PATH, query: {} };
  const firstbookUrl = { pathname: SIGNIN_FIRSTBOOK_PATH, query: {} };
  if (redirectTo) {
    firstbookUrl.query = { [SIGNIN_REDIRECT_QUERY_PARAM]: redirectTo };
    cleverUrl.query = { [SIGNIN_REDIRECT_QUERY_PARAM]: redirectTo };
  }

  return (
    <>
      {signinError && (
        <Flex
          aria-label={signinError}
          as={Alert}
          color="ui.danger"
          fontFamily="body"
          fontSize="lg"
          fontWeight="normal"
          justifyContent="center"
          lineHeight="taller"
          mt={2}
          textAlign="center"
        >
          {signinError}
        </Flex>
      )}
      <Flex
        flexWrap={["wrap", "wrap", "wrap", "nowrap"]}
        maxWidth="1100px"
        mt={0}
        mx={["1px", "1px", "auto"]}
        textAlign={["center", "center", "left"]}
      >
        <Flex direction="column" sx={column}>
          <Flex sx={logoHeader}>
            <CleverLogo height={["auto", null]} maxWidth="100%" mb={4} />
          </Flex>
          <NavButton
            aria-label="Sign in with Clever"
            href={cleverUrl}
            iconLeft={CleverBadge}
            size="lg"
            sx={{
              ...signinButton,
              color: "ui.clever.blue",
              "&:hover": {
                color: "ui.clever.blue",
              },
            }}
          >
            Sign in with Clever
          </NavButton>
        </Flex>
        <Flex direction="column" sx={column}>
          <Flex sx={logoHeader}>
            <FirstBookLogo height={["auto", null]} maxWidth="100%" mb={4} />
          </Flex>
          <NavButton
            aria-label="Sign in with First Book"
            href={firstbookUrl}
            iconLeft={FirstBookBadge}
            size="lg"
            sx={{
              ...signinButton,
              color: "ui.firstbook.blue",
              "&:hover": {
                color: "ui.firstbook.blue",
              },
            }}
          >
            Sign in with First Book
          </NavButton>
        </Flex>
      </Flex>
    </>
  );
};

export default SigninOption;

const column: SystemStyleObject = {
  mx: [0, null, 4],
  my: [2, null, 4],
  px: [4, null, 8],
  py: [8, null, 16],
  justifyContent: "flex-start",
  flexWrap: "nowrap",
  alignItems: "center",
  width: "100%",
  backgroundColor: "ui.gray.xlight",
};

const logoHeader: SystemStyleObject = {
  mb: 2,
  width: [150, 180, "100%"],
  height: "130px",
  alignItems: "center",
  justifyContent: "center",
};

const signinButton: SystemStyleObject = {
  display: "flex",
  flex: "0 0 auto",
  fontWeight: "normal",
  maxWidth: "100%",
  backgroundColor: "ui.white",
  borderRadius: "4px",
  mx: ["auto", "auto", 0],
  mt: [null, null, 4],
  mb: [null, null, 4],
  boxShadow: "button",
  _hover: {
    backgroundColor: "ui.gray.lightWarm",
    boxShadow: "none",
    textDecoration: "none",
  },
};
