import { Icon } from "@chakra-ui/react";
import * as React from "react";

const CleverLogo = (props: React.ComponentProps<typeof Icon>) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="249px"
    height="70px"
    viewBox="0 0 249 70"
    fill="none"
    role="img"
    aria-labelledby="Clever Logo"
    {...props}
  >
    <title>Clever Logo</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 35.1144C0 16.1336 14.0999 0.72998 34.3121 0.72998C46.7073 0.72998 54.1347 4.89115 60.2471 10.9261L51.042 21.6088C45.9768 16.9853 40.7898 14.1382 34.2147 14.1382C23.1345 14.1382 15.1227 23.3852 15.1227 34.725C15.1227 46.2595 22.9153 55.7012 34.2147 55.7012C41.7395 55.7012 46.342 52.6837 51.5047 47.9629L60.7098 57.3073C53.9399 64.5832 46.4151 69.1094 33.7276 69.1094C14.3677 69.1094 0 54.0951 0 35.1144Z"
      fill="#4274F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.0251 0H76.3198V68.9634H62.0251V0Z"
      fill="#4274F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.815 27.9358C96.8973 27.9358 93.0497 32.1943 91.9051 38.7159H113.432C112.604 32.2673 108.83 27.9358 102.815 27.9358ZM127.167 47.6709H92.0999C93.5123 54.1925 98.0175 57.5993 104.422 57.5993C109.219 57.5993 112.702 56.0906 116.647 52.3918L124.829 59.6677C120.129 65.5323 113.359 69.1094 104.252 69.1094C89.129 69.1094 77.927 58.4267 77.927 42.95V42.7554C77.927 28.3008 88.1792 16.4013 102.839 16.4013C119.666 16.4013 127.362 29.5418 127.362 43.8991V44.0938C127.362 45.5051 127.264 46.3568 127.167 47.6709Z"
      fill="#4274F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M154.538 68.8418H141.558L121.735 17.837H136.858L148.133 51.7591L159.505 17.837H174.36L154.538 68.8418Z"
      fill="#4274F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M192.867 27.9358C186.95 27.9358 183.102 32.1943 181.958 38.7159H203.485C202.657 32.2673 198.882 27.9358 192.867 27.9358ZM217.219 47.6709H182.152C183.565 54.1925 188.07 57.5993 194.475 57.5993C199.272 57.5993 202.754 56.0906 206.699 52.3918L214.882 59.6677C210.182 65.5323 203.412 69.1094 194.304 69.1094C179.181 69.1094 167.979 58.4267 167.979 42.95V42.7554C167.979 28.3008 178.232 16.4013 192.892 16.4013C209.719 16.4013 217.414 29.5418 217.414 43.8991V44.0938C217.414 45.5051 217.317 46.3568 217.219 47.6709Z"
      fill="#4274F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M248.245 32.4133C238.748 32.4133 232.928 38.1806 232.928 50.2747V68.9878H218.633V18.3238H232.928V28.5198C235.85 21.5359 240.55 16.9854 249 17.3747V32.389H248.245"
      fill="#4274F6"
    />
  </Icon>
);

export default CleverLogo;
