import { Icon } from "@chakra-ui/react";
import * as React from "react";

const FirstBookLogo = (props: React.ComponentProps<typeof Icon>) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="350px"
    viewBox="0 0 671.493 125.373"
    fill="none"
    role="img"
    aria-labelledby="FirstBook Logo"
    {...props}
  >
    <path
      fill="#9e1a3e"
      d="M624.132 84.254c-4.77 5.01-10.212 9.704-16.441 14.141-6.892 4.91-14.314 9.163-22.122 12.759-1.052.47-2.101.944-3.165 1.387-1.04.448-2.093.873-3.147 1.297-3.901 1.506-7.906 2.765-11.974 3.875 0 0-3.676 1.096-5.556 1.513-.079.061-.164.071-.246.128l29.43-.025c3.2-.096 6.404-.303 9.614-.627 11.607-1.17 22.766-4.494 33.388-9.055 6.848-2.939 13.098-6.702 18.638-11.413l-28.419-13.98"
    />
    <path
      fill="#36923b"
      d="M631.865 114.207a143.143 143.143 0 0 1-9.936 3.519l.027-.02c-.053.023-.113.04-.165.063-3.94 1.224-7.836 2.257-11.687 3.013-10.572 1.633-21.395 1.243-21.395 1.243h-37.041v1.833h99.62l-19.423-9.65"
    />
    <path
      fill="#694495"
      d="M608.02 50.126c-.288 1.047-.587 2.092-.876 3.139-.733 2.953-1.64 5.92-2.68 8.893-.23.696-.423 1.403-.67 2.093-3.667 10.288-9.169 19.868-15.738 28.59-3.595 4.677-11.335 13.967-21.51 21.696 1.497-.426 3.014-.806 4.478-1.335 1.632-.59 3.268-1.166 4.892-1.775 6.843-2.635 13.484-5.798 19.905-9.33 10.312-5.87 18.856-12.437 26.123-20.081l1.781-1.87c6.347-7.102 10.348-14.111 12.826-19.557a58.335 58.335 0 0 0 2.062-4.805c.052-.148.09-.269.138-.41.916-2.54 1.282-4.21 1.409-4.885.01-.054.019-.093.025-.134l.022-.118c.012-.067.018-.11.018-.11H608.02"
    />
    <path
      fill="#1f4b7e"
      d="m602.387 11.735-26.302 17.859c4.516 13.327 6.228 27.48 5.34 41.493-.63 9.984-2.561 20.007-5.43 29.613-.134.429-.306.92-.46 1.384 4.19-4.3 8.089-8.927 11.577-13.886 14.043-19.976 21.557-44.025 17.195-68.395-.486-2.707-1.043-5.456-1.92-8.068"
    />
    <path
      fill="#33aae4"
      d="M577.415 52.124c-1.251-9.294-3.638-18.31-7.516-26.96-2.838-6.325-6.347-12.217-10.784-17.53a64.842 64.842 0 0 0-6.87-7.063l-.654-.57v42.385l2.08 1.014c8.684 4.244 15.866 11.014 20.501 19.374a45.477 45.477 0 0 1 3.904 9.348c.07.242.144.483.21.726a113.14 113.14 0 0 0-.872-20.724M0 124.076h22.597V94.775h18.057V77.26H22.49V66.772h22.056l1.406-18.056H0v75.36M50.926 124.076h22.598v-75.36H50.926v75.36M107.258 81.044h-3.028V65.691h2.919c5.622 0 8.217 2.054 8.217 7.46 0 5.406-2.162 7.893-8.108 7.893zm17.19 8.866c9.407-2.595 13.516-8.11 13.516-19.678 0-15.462-10.488-21.516-30.383-21.516H81.632v75.36h22.598V95.965h.973c2.703 0 4.324.54 5.298 4.108l6.703 24.003h24.544l-9.082-26.057c-1.622-4.649-4.433-7.568-8.218-8.11M199.053 66.88h16.436v57.196h22.814V66.88h15.569l1.405-18.164h-56.224V66.88"
    />
    <path
      fill="#1f4b7e"
      d="M301.336 107.966h-6.27v-14.92h6.163c4.542 0 7.027 2.594 7.027 7.135 0 4.974-2.595 7.785-6.92 7.785zm-6.27-43.357h4.757c4.325 0 6.488 1.947 6.488 5.947 0 4.325-2.487 7.136-6.272 7.136h-4.974zm21.409 18.597c7.352-2.487 11.46-8.758 11.46-16.543 0-11.46-6.595-17.947-21.408-17.947h-33.518v75.36h30.707c18.38 0 26.921-8.11 26.921-22.705 0-8.974-5.297-16.003-14.162-18.165M365.995 107.966c-6.811 0-9.082-4.217-9.082-21.84 0-15.354 1.73-21.408 9.082-21.408 7.136 0 8.866 4.649 8.866 21.191 0 17.84-1.838 22.057-8.866 22.057zm0-60.548c-22.706 0-33.302 17.083-33.302 40.005 0 23.246 11.462 37.95 33.302 37.95 22.597 0 33.085-13.73 33.085-40.329 0-22.381-10.704-37.626-33.085-37.626M435.628 107.966c-6.812 0-9.083-4.217-9.083-21.84 0-15.354 1.731-21.408 9.083-21.408 7.136 0 8.865 4.649 8.865 21.191 0 17.84-1.838 22.057-8.865 22.057zm0-60.548c-22.705 0-33.301 17.083-33.301 40.005 0 23.246 11.461 37.95 33.301 37.95 22.597 0 33.085-13.73 33.085-40.329 0-22.381-10.704-37.626-33.085-37.626M474.05 100.106c2.295-2.022 4.553-4.077 6.713-6.246 6.23-6.266 11.6-13.013 15.897-20.465V48.682h-22.61v51.424M536.175 123.897l-1.294-3.025-15.824-37.035-.373-.872c-5.715 5.152-12.073 9.618-18.739 13.44l10.238 27.684h26.073l-.081-.192M474.84 106.623c-.263.061-.525.128-.79.198v17.268h22.61V98.224c-.413.22-.824.448-1.237.664-6.511 3.388-13.428 6.074-20.583 7.735"
    />
    <path
      fill="#33aae4"
      d="m178.576 76.318-.153-.027-6.019-1.056c-5.413-.975-6.604-3.032-6.604-5.522 0-1.44.584-2.614 1.713-3.439 1.162-.79 2.855-1.234 5.075-1.234 4.759 0 11.244 2.703 15.03 5.947l9.025-15.927.341-.398c-6.172-4.548-14.293-7.363-25.663-7.363-9.2 0-16.073 2.575-20.634 7.033-4.575 4.407-6.859 10.69-6.859 18.168 0 14.165 6.704 20.653 17.948 22.706l5.947 1.08c5.189.974 7.568 1.948 7.568 5.84 0 2.649-1.435 4.402-4.827 5.13-1.15.234-2.516.354-4.124.354-4.61 0-8.756-1.487-12.06-3.184l.007.015c-1.994-1.02-3.748-2.066-5.054-2.964l-7.352 17.3c5.298 2.92 13.84 6.595 24.652 6.595.659 0 1.306-.01 1.943-.028 18.977-.486 29.157-8.755 29.157-26.288 0-7.9-1.844-13.38-5.796-17.067-3.089-2.883-7.465-4.674-13.261-5.671M50.926 124.076h22.598v-75.36H50.926v75.36M107.258 81.044h-3.028V65.691h2.919c5.622 0 8.217 2.054 8.217 7.46 0 5.406-2.162 7.893-8.108 7.893zm17.19 8.866c9.407-2.595 13.516-8.11 13.516-19.678 0-15.462-10.488-21.516-30.383-21.516H81.632v75.36h22.598V95.965h.973c2.703 0 4.324.54 5.298 4.108l6.703 24.003h24.544l-9.082-26.057c-1.622-4.649-4.433-7.568-8.218-8.11M199.053 66.88h16.436v57.196h22.814V66.88h15.569l1.405-18.164h-56.224V66.88"
    />
    <path
      fill="#1f4b7e"
      d="M301.336 107.966h-6.27v-14.92h6.163c4.542 0 7.027 2.594 7.027 7.135 0 4.974-2.595 7.785-6.92 7.785zm-6.27-43.357h4.757c4.325 0 6.488 1.947 6.488 5.947 0 4.325-2.487 7.136-6.272 7.136h-4.974zm21.409 18.597c7.352-2.487 11.46-8.758 11.46-16.543 0-11.46-6.595-17.947-21.408-17.947h-33.518v75.36h30.707c18.38 0 26.921-8.11 26.921-22.705 0-8.974-5.297-16.003-14.162-18.165M365.995 107.966c-6.811 0-9.082-4.217-9.082-21.84 0-15.354 1.73-21.408 9.082-21.408 7.136 0 8.866 4.649 8.866 21.191 0 17.84-1.838 22.057-8.866 22.057zm0-60.548c-22.706 0-33.302 17.083-33.302 40.005 0 23.246 11.462 37.95 33.302 37.95 22.597 0 33.085-13.73 33.085-40.329 0-22.381-10.704-37.626-33.085-37.626M435.628 107.966c-6.812 0-9.083-4.217-9.083-21.84 0-15.354 1.731-21.408 9.083-21.408 7.136 0 8.865 4.649 8.865 21.191 0 17.84-1.838 22.057-8.865 22.057zm0-60.548c-22.705 0-33.301 17.083-33.301 40.005 0 23.246 11.461 37.95 33.301 37.95 22.597 0 33.085-13.73 33.085-40.329 0-22.381-10.704-37.626-33.085-37.626M474.05 100.106c2.295-2.022 4.553-4.077 6.713-6.246 6.23-6.266 11.6-13.013 15.897-20.465V48.682h-22.61v51.424M536.175 123.897l-1.294-3.025-15.824-37.035-.373-.872c-5.715 5.152-12.073 9.618-18.739 13.44l10.238 27.684h26.073l-.081-.192M474.84 106.623c-.263.061-.525.128-.79.198v17.268h22.61V98.224c-.413.22-.824.448-1.237.664-6.511 3.388-13.428 6.074-20.583 7.735"
    />
    <path
      fill="#33aae4"
      d="m178.576 76.318-.153-.027-6.019-1.056c-5.413-.975-6.604-3.032-6.604-5.522 0-1.44.584-2.614 1.713-3.439 1.162-.79 2.855-1.234 5.075-1.234 4.759 0 11.244 2.703 15.03 5.947l9.025-15.927.341-.398c-6.172-4.548-14.293-7.363-25.663-7.363-9.2 0-16.073 2.575-20.634 7.033-4.575 4.407-6.859 10.69-6.859 18.168 0 14.165 6.704 20.653 17.948 22.706l5.947 1.08c5.189.974 7.568 1.948 7.568 5.84 0 2.649-1.435 4.402-4.827 5.13-1.15.234-2.516.354-4.124.354-4.61 0-8.756-1.487-12.06-3.184l.007.015c-1.994-1.02-3.748-2.066-5.054-2.964l-7.352 17.3c5.298 2.92 13.84 6.595 24.652 6.595.659 0 1.306-.01 1.943-.028 18.977-.486 29.157-8.755 29.157-26.288 0-7.9-1.844-13.38-5.796-17.067-3.089-2.883-7.465-4.674-13.261-5.671"
    />
    <path
      fill="#1f4b7e"
      d="M534.8 48.682h-25.017c-.044.206-.091.424-.108.486-.67 2.435-1.34 4.873-2.074 7.292a97.3 97.3 0 0 1-1.424 4.375c-1.869 5.242-4.317 10.232-7.181 15.034-.639 1.072-1.3 2.135-1.98 3.188-.119.184-.236.368-.356.552a117.47 117.47 0 0 1-2.195 3.247 99.126 99.126 0 0 1-11.324 13.424c-.373.356-1.973 1.88-3.749 3.515-.424.377-.841.759-1.273 1.13-.144.123-.274.26-.404.396-1.05.941-2.046 1.806-2.766 2.37 1.079-.44 2.967-1.149 6.216-2.289.41-.144 1.095-.416 1.714-.67a115.36 115.36 0 0 0 13.781-6.415c.699-.384 1.388-.78 2.077-1.177 3.09-1.783 6.1-3.697 9.016-5.775a97.84 97.84 0 0 0 9.524-7.694 82.07 82.07 0 0 0 7.57-7.97c2.032-2.45 3.932-5.01 5.648-7.723 3.03-4.795 5.392-9.848 6.625-15.31l-2.32.014M656.117 113.248h-2.829v-1.344h7.356l-.112 1.344h-2.768v9.802h-1.647v-9.802M661.864 111.904h2.141l1.52 5.756c.272 1.024.527 2.288.656 3.136.128-.848.415-2.064.703-3.136l1.536-5.756h2.11l.96 11.146h-1.6l-.447-6.093c-.079-1.039-.176-2.495-.208-3.294-.143.815-.464 2.192-.736 3.23l-1.647 6.157h-1.407l-1.598-6.124a66.631 66.631 0 0 1-.768-3.279c-.034.8-.096 2.223-.176 3.343l-.448 6.06h-1.535l.944-11.146"
    />
  </Icon>
);

export default FirstBookLogo;
