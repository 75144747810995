import { Icon } from "@chakra-ui/react";
import * as React from "react";

const CleverBadge = (props: React.ComponentProps<typeof Icon>) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    role="img"
    aria-labelledby="Clever Badge"
    {...props}
  >
    <rect width="50" height="50" rx="4" fill="#4274F6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.49048 25.1047C8.49048 14.8919 15.94 6.60376 26.619 6.60376C33.1679 6.60376 37.0921 8.84273 40.3215 12.0899L35.4581 17.8379C32.7819 15.3501 30.0414 13.8182 26.5675 13.8182C20.7134 13.8182 16.4804 18.7937 16.4804 24.8952C16.4804 31.1015 20.5976 36.1817 26.5675 36.1817C30.5432 36.1817 32.9749 34.5582 35.7025 32.018L40.566 37.0459C36.9891 40.9608 33.0135 43.3962 26.3102 43.3962C16.0815 43.3962 8.49048 35.3176 8.49048 25.1047Z"
      fill="white"
    />
  </Icon>
);

export default CleverBadge;
