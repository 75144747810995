import { Box, Flex } from "@chakra-ui/react";
import React from "react";

/**
 * Adds wrapping components for styling
 */
const SigninWrapper: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <Box flex={1} mb={32}>
      <Flex alignItems="center" justifyContent="center">
        {children}
      </Flex>
    </Box>
  );
};

export default SigninWrapper;
