import LayoutPage from "components/chakra-ui/LayoutPage";
import { Box, Flex, Text } from "@chakra-ui/react";
import SigninWrapper from "components/chakra-ui/SigninWrapper";
import SigninOption from "components/chakra-ui/SigninOption";
import Head from "next/head";
import useClearLocalStorage from "hooks/useClearLocalStorage";
import React from "react";
import { SiteSectionLabel } from "oe-shared/analytics";
import { PageView } from "components/chakra-ui/PageView";
import { pageName } from "analytics/adobe";

const SigninPage: React.FC = () => {
  useClearLocalStorage();
  return (
    <LayoutPage>
      <Head>
        <title>Sign in</title>
      </Head>
      <PageView
        name={pageName.signin}
        category={SiteSectionLabel.MARKETING_SITE}
      />
      <SigninRegion />
    </LayoutPage>
  );
};

function SigninRegion(): JSX.Element {
  return (
    <SigninWrapper>
      <Flex direction="column" p={[1, 4]}>
        <Box textAlign="center">
          <Text
            as="h2"
            fontSize={["3xl", null, "5xl"]}
            fontWeight="medium"
            mt={[4, 16]}
            textStyle="headers.secondary"
          >
            Get Started
          </Text>
          <Text
            as="h3"
            fontSize={["xl", null, "3xl"]}
            fontWeight="normal"
            textStyle="headers.tertiary"
          >
            Sign-in to access the collection.
          </Text>
        </Box>
        <SigninOption />
      </Flex>
    </SigninWrapper>
  );
}

export default SigninPage;
