import React from "react";
import { ChakraProps, Button as ChakraButton, Text } from "@chakra-ui/react";
import LoadingIndicator from "../LoadingIndicator";
import Link from "../Link";
import { ColorScheme } from "ui/chakra/theme/components/button";

type ButtonOwnProps = {
  variant?: string;
  colorScheme?: ColorScheme;
  className?: string;
  iconLeft?: React.ComponentType<ChakraProps>;
  iconRight?: React.ComponentType<ChakraProps>;
};

/**
 * We provide three buttons that look the same, but operate differently:
 *  - Button: Renders an HTML button
 *  - NavButton: Renders a Link
 *  - AnchorButton: Renders an HTML anchor
 */
type ButtonProps = React.ComponentPropsWithoutRef<typeof ChakraButton> &
  ButtonOwnProps & {
    disabled?: boolean;
    loading?: boolean;
    loadingText?: string;
  } & ChakraProps;
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function (props, ref) {
    const {
      variant,
      colorScheme,
      loading,
      loadingText,
      disabled,
      iconLeft: _iconLeft,
      iconRight: _iconRight,
      ...rest
    } = props;

    return (
      <ChakraButton
        variant={variant}
        colorScheme={colorScheme}
        disabled={loading || disabled}
        ref={ref}
        aria-label={loading && loadingText ? loadingText : undefined}
        {...rest}
      >
        <ButtonContent {...props} />
      </ChakraButton>
    );
  },
);

Button.displayName = "Button";

const ButtonContent: React.FC<
  ButtonProps | NavButtonProps | AnchorButtonProps
> = ({ iconLeft: IconLeft, iconRight: IconRight, children, ...rest }) => {
  const loading = "loading" in rest ? rest.loading : false;
  const loadingText = "loadingText" in rest ? rest.loadingText : undefined;
  return (
    <>
      {!loading && IconLeft && <IconLeft sx={{ marginRight: 1 }} />}
      {!loading && children}
      {!loading && IconRight && <IconRight sx={{ marginLeft: 1 }} />}
      {loading && (
        <LoadingIndicator
          width="20px"
          height="20px"
          ml={loadingText ? -2 : undefined}
          mr={loadingText ? 2 : undefined}
        />
      )}
      {loading && loadingText && <Text>{loadingText}</Text>}
    </>
  );
};
/**
 * The nav button renders a Link, which already takes an "as" prop, so
 * we need to pass that through the PolymorphicBox via some other name
 */
type NavButtonProps = React.ComponentProps<typeof ChakraButton> &
  ButtonOwnProps;
export const NavButton = React.forwardRef<HTMLButtonElement, NavButtonProps>(
  function (props, ref) {
    const {
      variant,
      iconLeft: _iconLeft,
      iconRight: _iconRight,
      ...rest
    } = props;

    return (
      <ChakraButton as={Link} variant={variant} ref={ref} {...rest}>
        <ButtonContent {...props} />
      </ChakraButton>
    );
  },
);

NavButton.displayName = "NavButton";

/**
 * The nav button renders a HTML anchor tag, without "/app" appended to the href
 */
type AnchorButtonProps = React.ComponentProps<typeof ChakraButton> &
  ButtonOwnProps & { newTab?: boolean };
export const AnchorButton = React.forwardRef<
  HTMLButtonElement,
  AnchorButtonProps
>(function (props, ref) {
  const {
    variant,
    colorScheme,
    isLoading,
    newTab,
    iconLeft: _iconLeft,
    iconRight: _iconRight,
    ...rest
  } = props;
  return (
    <ChakraButton
      as="a"
      variant={variant}
      colorScheme={colorScheme}
      rel={newTab ? "noreferrer noopener" : undefined}
      target={newTab ? "__blank" : undefined}
      ref={ref}
      isLoading={!!isLoading}
      {...rest}
    >
      <ButtonContent {...props} />
    </ChakraButton>
  );
});

AnchorButton.displayName = "AnchorButton";

export default Button;
