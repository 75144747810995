import { Icon } from "@chakra-ui/react";
import * as React from "react";

const FirstBookBadge = (props: React.ComponentProps<typeof Icon>) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="50px"
    viewBox="0 0 205.04 251.533"
    fill="none"
    role="img"
    aria-labelledby="First Book Badge"
    {...props}
  >
    <path
      fill="#9e1a3e"
      d="M147.321 171.104c-9.686 10.175-20.74 19.706-33.39 28.718-13.998 9.971-29.07 18.607-44.926 25.911-2.135.957-4.268 1.918-6.426 2.818-2.113.91-4.252 1.771-6.391 2.634-7.923 3.059-16.057 5.615-24.32 7.87 0 0-7.464 2.225-11.283 3.072-.16.125-.333.145-.499.261l59.767-.052a276.484 276.484 0 0 0 19.526-1.272c23.57-2.374 46.232-9.13 67.806-18.39 13.908-5.969 26.598-13.61 37.848-23.178l-57.712-28.392"
    />
    <path
      fill="#36923b"
      d="M163.027 231.935a291.243 291.243 0 0 1-20.179 7.145l.052-.04c-.107.046-.228.081-.336.127-7.999 2.486-15.912 4.585-23.732 6.121-21.472 3.315-43.448 2.524-43.448 2.524H.158v3.721H202.47l-39.442-19.598"
    />
    <path
      fill="#694495"
      d="M114.6 101.797c-.585 2.124-1.191 4.247-1.779 6.374-1.49 5.996-3.33 12.022-5.444 18.06-.468 1.412-.857 2.849-1.357 4.251-7.45 20.894-18.622 40.347-31.963 58.06-7.3 9.5-23.019 28.366-43.684 44.063 3.04-.866 6.12-1.638 9.095-2.712 3.313-1.196 6.635-2.368 9.934-3.605 13.896-5.352 27.384-11.773 40.425-18.945 20.94-11.922 38.294-25.26 53.05-40.783l3.616-3.799c12.891-14.422 21.018-28.657 26.05-39.717 1.761-3.675 3.145-6.963 4.188-9.759.104-.3.181-.545.278-.832 1.859-5.158 2.604-8.548 2.862-9.92l.053-.273c.019-.107.032-.172.043-.239l.036-.224h-65.404"
    />
    <path
      fill="#1f4b7e"
      d="m103.159 23.828-53.414 36.27c9.173 27.066 12.649 55.809 10.846 84.266-1.283 20.276-5.201 40.632-11.029 60.14-.272.87-.62 1.867-.935 2.81 8.512-8.731 16.429-18.129 23.512-28.2 28.518-40.567 43.78-89.407 34.919-138.9-.984-5.498-2.117-11.08-3.9-16.386"
    />
    <path
      fill="#34aae4"
      d="M52.444 105.853c-2.538-18.876-7.385-37.186-15.264-54.75-5.761-12.846-12.887-24.811-21.9-35.6A131.71 131.71 0 0 0 1.33 1.157L0 0v86.078l4.224 2.059c17.636 8.62 32.223 22.368 41.635 39.345a93.026 93.026 0 0 1 6.193 13.65 94.645 94.645 0 0 1 1.735 5.334c.144.492.293.982.429 1.476a229.53 229.53 0 0 0-1.772-42.088"
    />
  </Icon>
);

export default FirstBookBadge;
